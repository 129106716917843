import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import {  setIntervalTimeOut, DOMAIN_URL, KIOSK_AUTO_LOGIN } from '../common/api';
import { KioskapiService } from '../services/kioskapi.service';
import { LocalStoreService } from '../services/localstore.service';

@Component({
  selector: 'app-retry-payment',
  templateUrl: './retry-payment.page.html',
  styleUrls: ['./retry-payment.page.scss'],
})
export class RetryPaymentPage implements OnInit {
  responseMessage;
  isForEventKiosk;
  page_redirection_time_left = 100;
  retry_time_left_redirection_counter: any;
  resetCount = 0;
  translatedText;
  constructor(
    private store: LocalStoreService, public modalController: ModalController,
    private kiosk_apiService : KioskapiService, private router: Router) { }

  ngOnInit() {
  }
  ionViewWillEnter() {
    this.Page_timer();
    this.getTranslatedText();
    if (this.store.get('retryPaymentCount')) {
      this.resetCount = +this.store.get('retryPaymentCount');
    }
  }
  getTranslatedText() {
    this.translatedText = this.store.get('translatedText');
  }

  Page_timer() {
   
    clearInterval(this.retry_time_left_redirection_counter);
    this.page_redirection_time_left = 10;
    if (this.page_redirection_time_left = 10) {
      this.retry_time_left_redirection_counter = setInterval(() => {
        this.page_redirection_time_left -= 1;
        if (this.page_redirection_time_left == 0) {
          this.kiosk_apiService.terminal_dismiss()
          clearInterval(this.retry_time_left_redirection_counter);
          let Autologin = this.store.get('autoLoginUrl')
          this.store.hardlogout()
          location.href = DOMAIN_URL + Autologin;
          return false;
        }
      }, setIntervalTimeOut);
    }
  }

  retryPayment() {
    this.resetCount = this.resetCount + 1;
    this.store.set('retryPaymentCount', this.resetCount)
    clearInterval(this.retry_time_left_redirection_counter);
    const feedBackSubmitted = {
    "dismiss_type": 'retry',
    "resetPaymentCount" : this.resetCount}
      this.modalController.dismiss(feedBackSubmitted);
  }
  cancelOrder() {
    clearInterval(this.retry_time_left_redirection_counter);
    this.store.remove("orderId");
    this.store.remove('sessionId');
    this.store.remove('qrBillId');
    if (this.isForEventKiosk == true || this.isForEventKiosk == 'true') {
      this.store.setUserCart([]);
      this.router.navigate([`home`]);
    } else {
      this.router.navigate([`cart-list`]);
    }
    const feedBackSubmitted = {
      "dismiss_type": 'cancelOrder'}
      this.modalController.dismiss(feedBackSubmitted);
      

  }

}
